import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'

import { AppWrap, MotionWrap } from '../../wrapper'
import './Experiences.scss'
import { client } from '../../client'

const Experiences = () => {
  const [skills, setSkills] = useState([]);
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    const skillsQuery = '*[_type == "skills"] | order(_createdAt asc)';
    const expQuery = '*[_type == "experiences"] | order(_createdAt asc)';

    client.fetch(skillsQuery).then((data) => {
      setSkills(data);
    });

    client.fetch(expQuery).then((data) => {
      setExperiences(data);
    });
  }, []);

  return (
    <>
      <div className="container">
        <h1 className="sub-title">Experiences</h1>
        <div className="app__skills">
          {skills.map((skill) => (
            <motion.div
              whileInView={{ opacity: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: 'spring' }}
              className="app__exp-item"
              key={skill.name}
            >
              <div className="related-skills">
                <p>{skill.name}</p>
              </div>

            </motion.div>
          ))}
        </div>
        {experiences.length && (
          <div className="app__experiences">
          {experiences.map((experience) => (
            <motion.div
              whileInView={{ opacity: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: 'spring' }}
              className="app__exp-item"
              key={experience.year}
            >
              <div className="app__exp-info">
                <h2>{experience.name}</h2>
                <p className="position" style={{ marginTop: 10, fontStyle: "italic", fontSize: '1.1rem'}}>{experience.position}</p>
                <p className="year" style={{ marginTop: 10 }}>{experience.year}</p>
                <p className="p-text" style={{ marginTop: 10 }}>{experience.description}</p>
                <div className="related-skills">

                  {experience.related.map((detail) => (
                    <p key={detail._id}>{detail.name}</p>
                  ))}
                </div>

              </div>
            </motion.div>
          ))}
        </div>
        )}
      </div >
    </>
  );
}

export default AppWrap(
  MotionWrap(Experiences, 'app__experiences'),
  'experiences'
);