import React from 'react'

import './Contact.scss'
import { FaLinkedinIn, FaGithub, FaFileAlt, FaEnvelope} from 'react-icons/fa';

const Contact = () => {
  return (
    <div id="contact">
      <div className="container">
        <h1 className="sub-title">Contact Me</h1>
        <div className="contact-socials">
          <a href="https://www.linkedin.com/in/victoria-choi/" target="_blank" rel="noopener noreferrer">
            <FaLinkedinIn />
          </a>
          <a href="https://github.com/vicchois" target="_blank" rel="noopener noreferrer">
            <FaGithub />
          </a>
          <a href="https://drive.google.com/file/d/1_fggcfryJaByhI_PMHuxUVlHAvCyFPjH/view?usp=drive_link" target="_blank" rel="noopener noreferrer">
            <FaFileAlt />
          </a>
          <a href="mailto:vchoi04@g.ucla.edu">
            <FaEnvelope />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact