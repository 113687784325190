import React, { useState, useEffect } from 'react'
import { AppWrap, MotionWrap } from '../../wrapper'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { FaLink } from 'react-icons/fa'
import './About.scss'
import { urlFor, client } from '../../client';

const About = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [abouts, setAbouts] = useState([]);
  const [pics, setPics] = useState([]);

  const handleClick = (index) => {
    setCurrentIndex(index);
  }

  useEffect(() => {
    const abtQuery = '*[_type == "abouts"] | order(_createdAt asc)';
    const picQuery = '*[_type == "pics"] | order(_createdAt asc)';

    client.fetch(abtQuery).then((data) => {
      setAbouts(data);
    })

    client.fetch(picQuery).then((data) => {
      setPics(data);
    })

  }, []);

  return (
    <>
      {pics.length && (
        <>
          <div id="about">
            <div className="container">
              <h1 className="sub-title">About Me</h1>

              <div className="about-me-list">
                <div className="about-pics">
                  <div className="app__pics-item app__flex">
                    <img src={urlFor(pics[currentIndex].pic)} alt={pics[currentIndex].name} />
                  </div>

                  <div className="pic-info">
                    <p className="p-text">{pics[currentIndex].caption}</p>
                    <div className="pic-buttons">
                      <div className="pic-button" onClick={() => handleClick(currentIndex === 0 ? pics.length - 1 : currentIndex - 1)}>
                        <HiChevronLeft />
                      </div>
                      <a className="pic-button" href={pics[currentIndex].link} target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                      <div className="pic-button" onClick={() => handleClick(currentIndex === pics.length - 1 ? 0 : currentIndex + 1)}>
                        <HiChevronRight />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="about-text">
                  {abouts.map((abouts) => (
                    <div
                      className="about-section"
                      key={abouts.title}
                    >

                      <h2>{abouts.title}</h2>
                      <div className="related-skills">

                        {abouts.related.map((detail) => (
                          <p key={detail.name}>{detail.name}</p>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about'
);