import React from 'react';
import { FaLinkedinIn, FaGithub, FaFileAlt, FaEnvelope} from 'react-icons/fa';

const Socials = () => (  
  <div className="app__social">
    <a href="https://www.linkedin.com/in/victoria-choi/" target="_blank" rel="noopener noreferrer">
      <FaLinkedinIn />
    </a>
    <a href="https://github.com/vicchois" target="_blank" rel="noopener noreferrer">
      <FaGithub />
    </a>
    <a href="https://drive.google.com/file/d/1_fggcfryJaByhI_PMHuxUVlHAvCyFPjH/view?usp=drive_link" target="_blank" rel="noopener noreferrer">
      <FaFileAlt />
    </a>
    <a href="mailto:vchoi04@g.ucla.edu">
      <FaEnvelope />
    </a>
  </div>
);

export default Socials