import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
  projectId: 'k8p6svb0',
  dataset: 'production', 
  apiVersion: '2023-09-26', 
  useCdn: true,
  token: process.env.REACT_APP_SANTIY_PROJECT_TOKEN, 
});

const builder = imageUrlBuilder(client); 

export const urlFor = (source) => builder.image(source);