import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper'
import './Projects.scss'
import { client } from '../../client'

const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const query = '*[_type == "projects" ] | order(_createdAt asc)';

    client.fetch(query).then((data) => {
      setProjects(data);
    });
  }, []);

  return (
    <>
      <div className="container">
        <h1 className="sub-title">Projects</h1>
        <div className="app__projects">
          {projects.map((projects, index) => (
            <motion.div
              whileInView={{ opacity: 1 }}
              whileHover={{ scale: 1.1}}
              transition={{ duration: 0.5, type: 'spring'}}
              className="app__project-item"
              key={projects.title + index}
            >
              <h2 style={{ marginTop: 20 }}>{projects.name}</h2>
              <p className="company" style={{ marginTop: 10, fontStyle:"italic", fontSize:'1.1rem' }}>{projects.company}</p>
              <p className="p-text" style={{ marginTop: 10}}>{projects.description}</p>
            </motion.div>
          ))}
        </div>
      </div >
    </>
  );
};

export default AppWrap(
  MotionWrap(Projects, 'app__projects'),
  'projects'
);