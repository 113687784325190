import React from 'react'
import {motion} from 'framer-motion';
import {AppWrap, MotionWrap} from '../../wrapper'
import './Home.scss'

const Home = () => {
  return (
    <div id="home">
      <div className="container">
      <motion.div
        whileInView={{x:[-100,0], opacity: [0,1]}}
        transition={{ duration: 0.5 }}
        className="header-text"
      >
        <div>
          <h1>Hello! My name is Victoria Choi.</h1>
        </div>
      </motion.div>
    </div>
    </div>
  );
}

export default AppWrap(
  MotionWrap(Home, 'app__home'),
  'home'
);