import React from 'react';
import { About, Contact, Home, Projects, Experiences } from './container';
import { Navbar } from './components';
import './App.scss';

const App = () => {
  return (
    <div className="app">
      <Navbar />
      <Home />
      <About />
      <Experiences />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;
